import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "known-bugs-and-issues"
    }}>{`Known bugs and issues`}</h2>
    <p>{`Here are the most significant known bugs and issues in Carbon v10. The Carbon team is working to resolve these.`}</p>
    <p>{`We also welcome and encourage pull requests. If you find and issue and fix it, we’ll be delighted to welcome you as a Carbon contributor!`}</p>
    <ul>
      <li parentName="ul">{`The toolbar on the Data Table component is not working `}<a parentName="li" {...{
          "href": "https://github.com/IBM/carbon-components/issues/1303"
        }}>{`[#1303]`}</a></li>
      <li parentName="ul">{`There is a text alignment issue on data table when using different cell sizes `}<a parentName="li" {...{
          "href": "https://github.com/IBM/carbon-components/issues/1303"
        }}>{`[#1303]`}</a></li>
      <li parentName="ul"><del parentName="li">{`The React version of Combobox is not working `}<a parentName="del" {...{
            "href": "https://github.com/IBM/carbon-components-react/pull/2158"
          }}>{`[#2158]`}</a></del></li>
      <li parentName="ul">{`The React version of MultiSelect has a styling issue which impacts functionality `}<a parentName="li" {...{
          "href": "https://github.com/IBM/carbon-components-react/pull/2090"
        }}>{`[#2090]`}</a></li>
      <li parentName="ul">{`There is a styling issue with validation errors on form elements, where the error message overlays the label of a neighboring element`}</li>
      <li parentName="ul">{`There is a styling issue with code snippet where the x-axis scrollbar is visible if an external mouse is attached to a device `}<a parentName="li" {...{
          "href": "https://github.com/IBM/carbon-components/issues/2305"
        }}>{`[#2305]`}</a></li>
      <li parentName="ul">{`There are styling issues with several skeleton state components (Progress bar, search, slider, tag, text area, text input, toggle, accordion)`}</li>
      <li parentName="ul">{`There is an issue with the Search component in the React storybook (but only in the storybook)`}</li>
    </ul>
    <h3 {...{
      "id": "post-release-updates"
    }}>{`Post-release updates`}</h3>
    <p>{`Our Github repositories each have a `}<inlineCode parentName="p">{`v10-update`}</inlineCode>{` milestone, which includes all issues we consider most urgent.`}</p>
    <p>{`If you use Zenhub, you can see all those issues on a single `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/milestone/6"
      }}>{`v10-update milestone board`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      